a:hover{
    transform: scale(1);
}

.card{
    transition:0.25s;
}
.card:hover{
    transform:scale(1.05);
}

.banner{
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    height:60%;
    width:150%;

}

.margin{
    margin: 100px;
}