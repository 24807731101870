.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Timeline customization */
.p-timeline {
  padding: 0.5rem;
  width: 100%;
  max-width: 2400px !important;
  margin: 0 auto !important;
  background-color: transparent !important;
}

/* Timeline separator styling with glow effect */
.p-timeline .p-timeline-event-separator {
  display: flex !important;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin: 0 1rem;
}

.p-timeline .p-timeline-event {
  display: flex !important;
  position: relative !important;
  min-height: 70px !important;
}

.p-timeline .p-timeline-event-marker {
  width: 1.2rem !important;
  height: 1.2rem !important;
  border: 2px solid #c770f0 !important;
  background-color: transparent !important;
  border-radius: 50% !important;
  margin: 0 !important;
  z-index: 1 !important;
  box-shadow: 0 0 10px #c770f0, 
              0 0 20px #c770f0, 
              0 0 30px #c770f0 !important;
}

.p-timeline .p-timeline-event-connector {
  width: 3px !important;
  background: linear-gradient(
    to bottom,
    #c770f0,
    #cd5ff8
  ) !important;
  box-shadow: 0 0 10px #c770f0,
              0 0 20px rgba(199, 112, 240, 0.5) !important;
  height: 100% !important;
}

/* Card styling */
.p-card {
  background: rgba(33, 33, 33, 0.95) !important;
  border: 1.5px solid #c770f0 !important;
  color: white !important;
  border-radius: 10px !important;
  box-shadow: 0 0 15px rgba(199, 112, 240, 0.3) !important;
  margin: 1rem !important;
  max-width: 1100px !important;
  width: 100% !important;
  backdrop-filter: blur(4px) !important;
  opacity: 1 !important;
  visibility: visible !important;
}

.p-card .p-card-title {
  color: #c770f0 !important;
  font-size: 1.25rem !important;
  font-weight: bold !important;
}

.p-card .p-card-subtitle {
  color: #cd5ff8 !important;
  font-size: 1rem !important;
}

.p-card .p-card-content {
  padding: 1rem !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

/* Timeline layout */
.p-timeline.p-timeline-alternate .p-timeline-event:nth-child(even) {
  flex-direction: row-reverse !important;
}

.p-timeline .p-timeline-event-content {
  opacity: 0;
}

.p-timeline .p-timeline-event-content.animate {
  animation: fadeIn 0.6s ease-out forwards;
}

/* Responsive adjustments */
@media screen and (max-width: 2000px) {
  .p-card {
    max-width: 1000px !important;
  }
}

@media screen and (max-width: 1600px) {
  .p-card {
    max-width: 900px !important;
  }
}

@media screen and (max-width: 1200px) {
  .p-card {
    max-width: 700px !important;
  }
}

@media screen and (max-width: 992px) {
  .p-card {
    max-width: 600px !important;
  }
}

@media screen and (max-width: 768px) {
  .p-card {
    max-width: 100% !important;
    margin: 0.5rem !important;
  }
  
  .p-timeline .p-timeline-event-content {
    margin: 0 0.5rem !important;
  }

  /* Mobile timeline adjustments */
  .p-timeline.p-timeline-left .p-timeline-event {
    flex-direction: row !important;
  }

  .p-timeline.p-timeline-left .p-timeline-event-opposite {
    display: none !important;
  }

  .p-timeline.p-timeline-left .p-timeline-event-content {
    text-align: left !important;
    margin-left: 1rem !important;
  }

  .p-timeline.p-timeline-left .p-timeline-event-separator {
    margin-right: 0 !important;
  }

  .p-timeline.p-timeline-left .p-timeline-event-connector {
    left: 0.5rem !important;
  }

  /* Adjust card padding for mobile */
  .p-card .p-card-body {
    padding: 0.75rem !important;
  }

  .p-card .p-card-content {
    padding: 0.5rem !important;
  }
}

/* Timeline animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.p-card-body
.p-card-title{
  background-color: transparent !important;
}

/* Card hover animation */
.p-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: transparent !important;
}

.p-card:hover {
  transform: translateY(-5px) !important;
  box-shadow: 0 0 25px rgba(199, 112, 240, 0.5) !important;
}

/* Add smooth transitions for layout changes */
.p-timeline .p-timeline-event {
  transition: flex-direction 0.3s ease;
}

.p-timeline .p-timeline-event-content {
  transition: margin 0.3s ease;
}
.TimelineCardImg{
  margin: 0 0 5% auto;
}

/* Add these to your existing styles */
.p-scrollpanel {
    background: transparent !important;
    border: none !important;
}

.p-scrollpanel .p-scrollpanel-wrapper {
    border-right: none !important;
}

.p-scrollpanel .p-scrollpanel-bar {
    background: #c770f0 !important;
    opacity: 0.3;
}

.p-button.p-button-link:not(:disabled):hover {
    background: transparent !important;
    color: #cd5ff8 !important;
    text-decoration: underline !important;
}
