.sticky{
    background-color: white;
}

.navbar{
    background-color: black;
}

.left-nav a { 
    transform: scale(1.3);
}

.left-nav-mobile{
    margin-left:1vh !important;
}

.left-nav a:hover{
    transform: scale(1.8);
    color:#be6adf
}

a{
    transition:0.3s;
}

a:hover{
    transform: scale(1.2);
    color:#be6adf
}

.headerRightLinks{
    font-size: 1em !important;
    
}

/* @media (max-width: 500px) {
    .headerRightLinks{
        font-size: 0.6em !important;
        margin-left: 0 auto !important;
    }
} */